<template>
    <div class="monitor-ycysfbt">
        <div class="selector">
            <el-select v-model="year" placeholder="请选择">
                <el-option
                    v-for="item in years"
                    :key="item"
                    :label="item"
                    :value="item"
                >
                </el-option>
            </el-select>
        </div>
        <div class="download-list">
            <div
                v-for="({ MC, FjList }, index) in files"
                :key="index"
                class="item"
            >
                <div>
                    <span>{{ MC }}</span>
                    <el-checkbox
                        v-show="showCheckBox"
                        v-model="checkedList[index]"
                        @change="
                            (check) => {
                                changeChecked(check, index);
                            }
                        "
                    ></el-checkbox>
                </div>
                <div v-for="(item, index) in FjList" :key="index">
                    <span style="margin-right:10px;">{{ item.WDMC }}</span>
                    <a :href="item.CCLJ" download="" target="_blank">下载</a>
                </div>
            </div>
            <div v-if="!hasFile" class="empty">暂无数据</div>
        </div>
        <div class="map_box">
            <MyMap :myMap.sync="myMap" :mapData="mapData" />
        </div>
    </div>
</template>

<script>
import MyMap from "@comp/map/BaseMap.vue";
import configMap from "../0_com_js/configMap.js";
export default {
    components: {
        MyMap,
    },
    mixins: [configMap],
    props: {},
    data() {
        return {
            myMap: null,
            mapData: {
                zoom: 12.6,
                baseMapNum: 0,
                mapTypeBtn: false,
            },
            mc: "使用功能基准图",
            itemId: 303,
        };
    },
    computed: {},
};
</script>
<style lang="scss" scoped>
.monitor-ycysfbt {
    position: relative;
    .map_box {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }
    .selector {
        left: 45px;
        top: 45px;
        width: 200px;
        position: absolute;
        z-index: 999;
        box-sizing: content-box;
        background: #fff;
        .file-item {
            height: 45px;
            line-height: 45px;
            padding: 0 15px 0 0;
            background: #fff;
            display: flex;
            justify-content: space-between;
            > a {
                display: inline-block;
                padding-left: 25px;
                background-image: url("~@image/working_desk/monitor_data/download.png");
                background-repeat: no-repeat;
                background-position: left center;
                cursor: pointer;
                color: #5ab0f7;
            }
        }
    }
    .download-list {
        left: 45px;
        top: 100px;
        position: absolute;
        z-index: 999;
        .empty {
            height: 45px;
            line-height: 45px;
            padding: 0 30px 0;
            background: #fff;
            display: flex;
            justify-content: space-between;
        }
        .item {
            height: auto;
            padding: 0 15px 0;
            background: #fff;
            &:nth-of-type(n + 2) {
                border-top: 1px solid #ddd;
            }
            div {
                height: 45px;
                line-height: 45px;
                padding: 0 15px 0;
                background: #fff;
                display: flex;
                justify-content: space-between;
                a {
                    display: inline-block;
                    padding-left: 25px;
                    background-image: url("~@image/working_desk/monitor_data/download.png");
                    background-repeat: no-repeat;
                    background-position: left center;
                    cursor: pointer;
                    color: #5ab0f7;
                }
            }
        }
    }
}
</style>
